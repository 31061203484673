import { isBrowser } from '@/helpers/Vault/isBrowser';
import { useSetMobileDevice } from '@/web-sdk/hooks/useMobileDevice';
import { useTickers } from '@/web-sdk/hooks/websocket/useTickers';
import { useAppSelector } from '@/web-sdk/providers/ReduxProvider/app/hooks';
import { NextIcon, PrevIcon } from 'assets/images/Arrow';
import { dragAndScroll } from 'helpers/dragAndScroll';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TickerItem } from './TickerItem';

export const TickersList = () => {
    const router = useRouter();
    const tickers = useAppSelector(state => state.tickers.tickers);
    const markets = useAppSelector(state => state.markets.markets);

    const ref = useRef<HTMLDivElement>(null);
    const refWrapper = useRef<HTMLDivElement>(null);
    const refTickers = useRef<HTMLDivElement>(null);
    const isMobile = useSetMobileDevice();
    const [itemOverflown, setItemOverflown] = useState<boolean>(false);
    const [hover, setHover] = useState<boolean>(false);
    const [scrollPosition, setScrollPosition] = useState<number>(0);
    const [isScrolling, setIsScrolling] = useState<boolean>(false);
    const [clientX, setClientX] = useState<number>(0);
    const [scrollX, setScrollX] = useState<number>(0);

    useTickers(router.pathname);

    const MAX_DISPLAYED_MARKETS = 20;

    const list = useMemo(() => {
        const data = markets.length ? [...markets] : [];

        return data.sort((m1, m2) => (+(tickers[m1.id]?.volume || 0) > +(tickers[m2.id]?.volume || 0) ? -1 : 1));
    }, [markets, tickers]);

    useEffect(() => {
        if (isBrowser()) {
            const handleResize = () => {
                const element = ref.current;

                if (element) {
                    const hasOverflowingChildren =
                        element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth;

                    setItemOverflown(hasOverflowingChildren);
                }
            };

            window?.addEventListener('resize', handleResize);
            handleResize();
            return () => window?.removeEventListener('resize', handleResize);
        }
    }, [isBrowser, refTickers.current?.childNodes.length]);

    const handleScrollLeft = useCallback(() => {
        const element = ref.current;

        if (element) {
            const nextPosition = scrollPosition - element.clientWidth > 0 ? scrollPosition - element.clientWidth : 0;

            element.scroll({ left: nextPosition, behavior: 'smooth' });
            setScrollPosition(nextPosition);
        }
    }, [ref, scrollPosition]);

    const handleScrollRight = useCallback(() => {
        const element = ref.current;
        const innerContainer = refWrapper.current;
        const paddingSize = 24;

        if (element && innerContainer) {
            const mostRightPosition = innerContainer.clientWidth - element.clientWidth;
            const nextPosition =
                scrollPosition + element?.clientWidth > mostRightPosition
                    ? mostRightPosition + paddingSize
                    : scrollPosition + element?.clientWidth;

            element.scroll({ left: nextPosition, behavior: 'smooth' });
            setScrollPosition(nextPosition);
        }
    }, [ref, refWrapper, scrollPosition]);

    const [handleDragEnter, handleDragEnd, handleDrag] = dragAndScroll(
        setIsScrolling,
        setClientX,
        setScrollX,
        refWrapper,
        ref,
        clientX,
        scrollX,
        isMobile,
        isScrolling,
    );

    return markets.length ? (
        <div
            className="overflow-x-auto no-scrollbar pl-[18px] pr-[10px] sm:px-6 lg:px-3 pt-3 min-h-[4.025rem]"
            ref={ref}>
            <div className="table-row" ref={refWrapper}>
                <div
                    className="flex items-center"
                    ref={refTickers}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}>
                    {hover && !isMobile && itemOverflown && (
                        <div
                            className="absolute left-0 flex items-center justify-center w-12 h-20 bg-body-background-color cursor-pointer"
                            onClick={() => handleScrollLeft()}>
                            <PrevIcon fill="var(--app-neutral-control-layer-color-40)" disabled={false} />
                        </div>
                    )}
                    {list?.length ? (
                        [...list]
                            .splice(0, MAX_DISPLAYED_MARKETS)
                            .map((market, index) => (
                                <TickerItem
                                    position={index}
                                    key={market.id}
                                    market={market}
                                    tickers={tickers}
                                    handleDrag={handleDrag}
                                    handleDragEnd={handleDragEnd}
                                    handleDragEnter={handleDragEnter}
                                />
                            ))
                    ) : (
                        <></>
                    )}
                    {hover && !isMobile && itemOverflown && (
                        <div
                            className="absolute right-0 flex items-center justify-center w-12 h-20 bg-body-background-color cursor-pointer"
                            onClick={() => handleScrollRight()}>
                            <NextIcon fill="var(--app-neutral-control-layer-color-40)" disabled={false} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    ) : null;
};
