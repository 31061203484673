import getConfigs from './app';

export const SOCKET_URL = getConfigs().finexUrl;

export const REQUEST_CODE = 1;
export const RESPONSE_CODE = 2;
export const PUBLIC_EVENT_CODE = 3;
export const PRIVATE_EVENT_CODE = 4;

export const defaultSocketConfig = {
    share: true,
    retryOnError: true,
    shouldReconnect: () => true,
};
