import { useCallback } from 'react'

export const dragAndScroll = (
  setIsScrolling: React.Dispatch<React.SetStateAction<boolean>>,
  setClientX: React.Dispatch<React.SetStateAction<number>>,
  setScrollX: React.Dispatch<React.SetStateAction<number>>,
  refWrapper: React.RefObject<HTMLDivElement>,
  ref: React.RefObject<HTMLDivElement>,
  clientX: number,
  scrollX: number,
  isMobile: boolean | undefined,
  isScrolling: boolean,
): [(event: any) => void, () => void, (event: any) => void] => {
  const handleDragEnter = (event: any) => {
    setIsScrolling(true)
    setClientX(event.clientX)
  }
  const handleDragEnd = () => {
    setIsScrolling(false)
  }

  // TODO :: eslint
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const handleDrag = useCallback(
    (event: any) => {
      event.preventDefault()
      const innerContainer = refWrapper.current

      if (!isMobile && isScrolling && ref.current && innerContainer && event.clientX !== clientX && event.clientX > 0) {
        const mostRightPosition = innerContainer.clientWidth - ref.current.clientWidth
        const dragScrollPosition = -(scrollX + event.clientX - clientX)
        const scrollTo =
          dragScrollPosition >= mostRightPosition
            ? mostRightPosition + 24
            : dragScrollPosition >= 0
            ? dragScrollPosition
            : 0

        ref.current.scroll({
          left: scrollTo,
          behavior: 'auto',
        })
        dragScrollPosition > 0 &&
          dragScrollPosition <= mostRightPosition &&
          setScrollX((prev: number) => prev + event.clientX - clientX)
        setClientX(event.clientX)
      }
    },
    [isScrolling, scrollX, clientX, ref, isMobile, refWrapper],
  )

  return [handleDragEnter, handleDragEnd, handleDrag]
}
